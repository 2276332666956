<template>
  <div class="page">
    <div>
      <p>sign：{{ sign ? sign : "无" }}</p>
      <p>id：{{ id ? id : "无" }}</p>
      <p>url：{{ url ? url : "无" }}</p>
      <p>ts：{{ ts ? ts : "无" }}</p>
      <p>-------------------------</p>
      <p>code：{{ code ? code : "无" }}</p>
      <p>-------------------------</p>
      <p>响应：{{ datas ? datas : "无" }}</p>
    </div>
  </div>
</template>

<script>
import { wxMarsLogin } from "@/api/api.js"; //引入
export default {
  data() {
    return {
      datas:null,
      sign: "",
      id: "",
      url: "",
      ts: "",
      decodedUrl: "",
      code: "",
    };
  },
  created() {
    let queryString = window.location.search;
    // let queryString = `https://zxw.cmdc-ecss.cn/blw/reauth/?id=4933262&sign=6f218b250d0b77e6c6273af3fa509d16&ts=1718611533579&url=https://meet.polyv.net/custom/4933262#/`;
    // let urlParams = new URLSearchParams(queryString);
    // 创建 URL 对象
    let urlObj = new URL(queryString);

    let code1=this.$route.query.code?this.$route.query.code:''
    let sign1=this.$route.query.sign?this.$route.query.sign:''
    let id1=this.$route.query.id?this.$route.query.id:''
    let url1=this.$route.query.url?this.$route.query.url:''
    let ts1=this.$route.query.ts?this.$route.query.ts:''
    // 获取 URL 参数
    let code = urlObj.searchParams.get('code')||code1
    let sign = urlObj.searchParams.get('sign')||sign1
    let id = urlObj.searchParams.get('id')||id1
    let url = urlObj.searchParams.get('url')||url1
    let ts = urlObj.searchParams.get('ts')||ts1
   
    let data = {
      code: code,
      liveId: id,
      ts,
      sign,
      redirectUrl: url,
    };


    // let data={
    //   openId: '123',
    //   liveId:'4933262',
    //   ts:'1718605193427',
    //   sign:'b4640e28006f253f0e02eea29cfaf40b',
    //   redirectUrl:'https://meet.polyv.net/custom/4933262'
    // }

    wxMarsLogin(data).then((res) => {
      this.datas=res.data
      if(res.data.code===0){
        window.location.href = res.data;
      }else{
          this.$router.push('/error')
      }
    });
  },
  methods: {},
};
</script>
