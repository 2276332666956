<template>
  <div class="page">
    <div>
      <p>页面出错啦~</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
     
    };
  },
  created() {
    
  },
  methods: {},
};
</script>

<style>
  
</style>
