import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


// 引入 Vconsole
import Vconsole from 'vconsole'
// 所有环境均使用
// new Vconsole()
// // 在 test 环境才使用
// process.NODE_ENV === 'test' ? new Vconsole : new Vconsole


Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
