<template>
  <div class="page">
  
  </div>
</template>

<script>
const isWechat = () => {
  return (
    String(navigator.userAgent.toLowerCase().match(/MicroMessenger/i)) ===
    "micromessenger"
  );
};
import { wxMarsLogin } from "@/api/api.js"; //引入
export default {
  data() {
    return {
      sign: "",
      id: "",
      url: "",
      ts: "",
      decodedUrl: "",
      code: "",
      queryParams: "",
      respon: "",
      oldUrl:''
    };
  },
  created() {

    let queryString = window.location.search;
    queryString = queryString.startsWith("?")
      ? queryString.slice(1)
      : queryString;
    console.log("queryString", queryString);
    let searchParams = new URLSearchParams(queryString);
    let code = searchParams.get("code");
    let sign = "";
    let id = "";
    let url = "";
    let ts = "";
    let authType = "";
    let corpId = "";
    let oldUrl= sessionStorage.getItem('oldUrl')
    if(oldUrl){
      this.oldUrl=oldUrl.replace('#/', '')
    }
    if (code) {
      sign = searchParams.get("sign");
      id = searchParams.get("id");
      url = searchParams.get("url");
      ts = searchParams.get("ts");
      authType = searchParams.get("authType")||'wx';
      corpId = searchParams.get("corpId");
      ts = searchParams.get("ts");
      let data = {
        code: code,
        liveId: id,
        ts,
        sign,
        redirectUrl: url,
        corpId,
        authType,
        redirectQwUrl:this.oldUrl
      };
      console.log("已经获取code", data);
      this.toLogin(data);
    } else {
      sessionStorage.setItem('oldUrl',window.location.href)
      this.oldUrl=window.location.href
      this.queryParams = queryString;
      this.getCode()
      console.log("未获取code", this.queryParams);
    }

  },
  methods: {
    toLogin(data) {
      wxMarsLogin(data).then((res) => {
        this.datas = res.data;
        console.log('响应数据',res);
        console.log('响应数据code',res.code);
        if (res.code === 0) {
          window.open(res.data, '_self');
        } else {
          this.$router.push("/error");
        }
      });
    },
    getCode() {
      this.getWxH5Code();
    },
    getCode1() {
      this.getWxH5Code1();
    },

    getWxH5Code() {
      if (isWechat) {
        let appid = "wxcff3e20266b7bb17"; //自己的微信APPid  可以通过接口让后端给，也可以前端写死
        let code = this.getWxUrlCode().code; //是否存在code

        // let local = `https://zxw.cmdc-ecss.cn/blw/reauth/?`; //根据自己的配置调整
        let local = `https://hotdesktest.marschina.com/index.html?`; //根据自己的配置调整
        // let local = `http://b.gdzyxx.cn:13000/index.html?`; //根据自己的配置调整
        if (this.queryParams) {
          local = local + this.queryParams;
        }

        console.log("local", local);
        if (code == null || code === "") {
          //不存在就打开上面的地址进行授权
          window.location.href =
            "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
            appid +
            "&redirect_uri=" +
            encodeURIComponent(local) +
            "&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect";
          //snsapi_base （不弹出授权页面，直接跳转，只能获取用户openid），
          //snsapi_userinfo （弹出授权页面，可通过openid拿到昵称、性别、所在地。
          //并且， 即使在未关注的情况下，只要用户授权，也能获取其信息 ）
        } else {
          console.log("获取微信的code:", code);
          this.code = code;
        }
      }
    },
    getWxH5Code1() {
      if (isWechat) {
        let appid = "wweec5a4cdc739ab1c"; //自己的微信APPid  可以通过接口让后端给，也可以前端写死
        let code = this.getWxUrlCode().code; //是否存在code

        let local = `http://b.gdzyxx.cn:13000/index.html?`; //根据自己的配置调整
        if (this.queryParams) {
          local = local + this.queryParams;
        }

        console.log("local", local);
        if (code == null || code === "") {
          //不存在就打开上面的地址进行授权
          window.location.href =
            "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
            appid +
            "&redirect_uri=" +
            encodeURIComponent(local) +
            "&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect";
          //snsapi_base （不弹出授权页面，直接跳转，只能获取用户openid），
          //snsapi_userinfo （弹出授权页面，可通过openid拿到昵称、性别、所在地。
          //并且， 即使在未关注的情况下，只要用户授权，也能获取其信息 ）
        } else {
          console.log("获取微信的code:", code);
          this.code = code;
        }
      }
    },
    getWxUrlCode() {
      var url = location.search; //根据自己的配置调整
      var wxCode = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          wxCode[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return wxCode;
    },
  },
};
</script>

<style lang="less" scoped>
*{
  margin: 0;
  padding: 0;
}
	.page{
		width: 750rpx;
		height: 97vh;
    overflow: hidden;
		background-image: url('@/assets/bg-mars.jpg');
		background-repeat: no-repeat;
		background-size: 100% 100%;
    box-sizing: border-box;
	}
</style>
